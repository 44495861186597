<template>
  <Page title="法律援助在线办理">
    <applyheader :post="post"></applyheader>
    <article id="page-apply" class="mt-4 mb-4">
      <div class="container container-main">
        <div class="applyCon_zzy p-4">
          <div class="applyC">
            <div class="p-5">
              <a-steps>
                <a-step :status="true?'finsh' :'wait'" title="申报需知">
                  <a-icon slot="icon" type="solution" :style="{ fontSize: '42px', color: '#004996'}"/>
                </a-step>
                <a-step :status="util.stepStatus >= 2?'finsh' :'wait'" title="基本资料">
                  <a-icon slot="icon" type="form" :style="{ fontSize: '42px'}"/>
                </a-step>
                <a-step :status="util.stepStatus >= 3?'finsh' :'wait'" title="材料提交">
                  <a-icon slot="icon" type="upload"  :style="{ fontSize: '42px'}"/>
                </a-step>
                <a-step :status="util.stepStatus >= 4?'finsh' :'wait'" title="提交完成">
                  <a-icon slot="icon" type="smile-o"  :style="{ fontSize: '42px'}"/>
                </a-step>
              </a-steps>
            </div>
            <div v-if="util.stepStatus == 1" class="p-4">
              <a-row type="flex" justify="space-around" align="middle">
                <a-col :span="18" class="justify-Wechat">
                  <a-alert
                    message="温馨提示!"
                    description="请仔细阅读以下内容,微信扫描右侧二维码，手机端办理更便捷！"
                    type="warning"
                    show-icon
                  />
                  <div class="Wechat">
                    <img src="../../assets/images/weixin.png" alt="erweima">
                  </div>
                </a-col>
                <a-col :span="22">
                  <div class="risk_inner mb-4 mt-4">
                    <div class="ant-card-head mb-4">
                      <a-icon type="copy" />办理须知
                    </div>
                    <p>1.申请人保证以上本人填写的情况及提供的资料真实有效；</p>
                    <p>2.申请人应如实陈述案件事实及相关情况，及时全面提供有关证明和证据材料；</p>
                    <p>3.申请人应协助调查法律援助事项的事实；</p>
                    <p>4.法律援助人员在办案过程中，发现受援人因经济状况改善，不再符合法律援助条件的，经与法律援助机构协商，可以继续接受法律服务，但应当支付服务费用；</p>
                    <p>5.有下列情形之一的，法律服务机构或者法律援助人员可以申请终止法律援助：</p>
                    <p class="padL20">①因受援人不履行义务严重影响法律援助人员办案的；</p>
                    <p class="padL20">②受援人提供虚假证明或者要求违法提供服务的；</p>
                    <p class="padL20">③在法律援助过程中发现受援人不符合受援条件的。</p>

                  </div>
                </a-col>
                <a-col :span="3">
                  <a-button type="danger" block @click=" ()=>{
                  this.util.stepStatus++
                }">
                    下一步
                  </a-button>
                </a-col>
              </a-row>
            </div>
            <div>
              <!--资料提交-->
              <div v-if="util.stepStatus === 2" class="pb-5">
                <a-form-model ref="ruleForm" :model="applyInfo" :rules="rules" v-bind="layout">
                  <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="18">
                      <a-form-model-item has-feedback prop="sponsor_name" label="姓名" >
                        <a-input placeholder="请输入您的姓名" v-model="applyInfo.sponsor_name">
                        </a-input>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="18">
                      <a-form-model-item prop="peo_sex" label="性别">
                        <a-radio-group v-model="applyInfo.peo_sex" >
                          <a-radio v-for="item in other.gender" :key="item.item_value" :value='item.item_value' class="select-spacing">
                            {{ item.item_name }}
                          </a-radio>
                        </a-radio-group>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="18">
                      <a-form-model-item has-feedback  label="民族" prop="nation">
                        <a-select class="wight-judge" v-model="applyInfo.nation">
                          <a-select-option v-for="value in other.nation" :key="value.item_value">
                            {{ value.item_name }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="18">
                      <a-form-model-item has-feedback prop="sponsor_phone" label="手机号" >
                        <a-input placeholder="请输入11位手机号码" v-model="applyInfo.sponsor_phone">
                        </a-input>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="18">
                      <a-form-model-item has-feedback  label="市/州" prop="city">
                        <a-select class="wight-judge" @change="cityChange($event, util.city)" :default-value="applyInfo.city">
                          <a-select-option v-for="value in util.city" :key="value.district_id">
                            {{ value.district_name }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="18">
                      <a-form-model-item has-feedback label="区/县" prop="area">
                        <a-select class="wight-judge" v-model="applyInfo.area">
                          <a-select-option v-for="value in util.area" :key="value.district_name">
                            {{ value.district_name }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="18">
                      <a-form-model-item has-feedback prop="sponsor_idcard_no" label="身份证号">
                        <a-input placeholder="请输入18位身份证号码" v-model="applyInfo.sponsor_idcard_no">
                        </a-input>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="18">
                      <a-form-model-item has-feedback prop="order_desc" label="描述">
                        <a-textarea placeholder="您的问题描述" v-model="applyInfo.order_desc"
                                    :rows="4"
                        >
                        </a-textarea>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                      <div class="income">
                        <a-card :bordered="false">
                          <p slot="title" class="secondTitle"><a-icon type="account-book" />家庭月收入范围</p>
                          <a-form-model-item prop="income">
                            <a-radio-group v-model="applyInfo.income" >
                              <a-radio-button v-for="item in other.income" :key="item.item_value" :value="item.item_value" class="select-spacing">
                                {{ item.item_name }}
                              </a-radio-button>
                            </a-radio-group>
                          </a-form-model-item>
                        </a-card>
                      </div>
                    </a-col>
                    <a-col :span="24">
                      <div class="identity">
                        <a-card :bordered="false">
                          <p slot="title" class="secondTitle"><a-icon type="usergroup-add" />身份选择</p>
                          <a-form-model-item prop="profile">
                            <a-checkbox-group  v-model="profiles" prop="profile">
                              <a-checkbox v-for="item in other.profile" :key="item.item_value" :value="item.item_value" class="select-spacing">
                                {{ item.item_name }}
                              </a-checkbox>
                            </a-checkbox-group>
                          </a-form-model-item>
                        </a-card>
                      </div>
                    </a-col>
                    <a-col :span="24">
                      <div class="matter">
                        <a-card :bordered="false">
                          <p slot="title" class="secondTitle"><a-icon type="project" />请选择法律援助事由</p>
                          <a-form-model-item prop="why">
                            <a-radio-group v-model="applyInfo.reason" >
                              <a-radio-button v-for="item in other.why" :key="item.item_value" :value="item.item_value" class="select-spacing">
                                {{ item.item_name }}
                              </a-radio-button>
                            </a-radio-group>
                          </a-form-model-item>
                        </a-card>
                      </div>
                    </a-col>
                    <a-col :span="24">
                      <div class="matter">
                        <a-card :bordered="false">
                          <p slot="title" class="secondTitle"><a-icon type="usergroup-delete" />请选择身份类型</p>
                          <a-form-model-item prop="profileType">
                            <a-checkbox-group  v-model="human_type" prop="profileType">
                              <a-checkbox v-for="item in other.profileType" :key="item.item_value" :value="item.item_value" class="select-spacing">
                                {{ item.item_name }}
                              </a-checkbox>
                            </a-checkbox-group>
                          </a-form-model-item>
                        </a-card>
                      </div>
                    </a-col>
                    <a-alert
                      message="即将完成"
                      description="系统初步审核后提交申请材料"
                      type="info"
                      show-icon
                      class="mb-3 mt-3"
                    />
                    <a-col :span="24" class="text-center mt-4">
                      <a-button type="danger" block @click="()=>{
                      this.util.stepStatus --
                    }" class="prev">
                        上一步
                      </a-button>
                      <a-button type="primary" block class="next" @click="onclick">
                        下一步
                      </a-button>
                    </a-col>
                  </a-row>
                </a-form-model>
              </div>
              <div v-if="util.stepStatus == 3" class="pb-5">
                <!--提交完成-->
                <a-row type="flex" justify="space-around" align="middle" >
                  <a-col :span="22">
                    <div>
                      <!--                  <a-card :bordered="false">-->
                      <!--                  <p slot="title" class="secondTitle"><span class="float-left"><a-icon type="account-book" />法律援助申请材料提交</span>-->
                      <!--                    <a target="_blank" href="/gs12348/api/workorder/01772d3fd2178a8382e9772981ea078a/workorder_file/01773304a6508a8382e9772981ea1a52?_file=file&_mode=inline" download="法律援助申请委托书.png"><a-icon type="download" /><span>法律援助申请委托书</span></a>-->
                      <!--                    <a target="_blank" href="/gs12348/api/workorder/01772d3fd2178a8382e9772981ea078a/workorder_file/0177330b41aa8a8382e9772981ea1a92?_file=file&_mode=inline" download="法律援助申请书.png"><a-icon type="download" /><span>法律援助申请书</span></a>-->
                      <!--                    <a target="_blank" href="/gs12348/api/workorder/01772d3fd2178a8382e9772981ea078a/workorder_file/017732fc98048a8382e9772981ea19e9?_file=file&_mode=inline" download="法律援助申请人经済状况证明表.png"><a-icon type="download" /><span>法律援助申请人经済状况证明表</span></a>-->
                      <!--                  </p>-->
                      <!--                  </a-card>-->
                    </div>
                  </a-col>
                  <a-col :span="18" class="pt-4 pb-4">
                    <p>
                      <a-tag color="#004996">
                        1
                      </a-tag>
                      <span style="margin-left: 5px">申请人的《居民身份证》、《户口簿》</span>
                      <span style="margin-left: 20px">（因出境已注销户口的，需原居住地公安部门出具户籍记载情况的证明）</span>
                    </p>
                  </a-col>
                  <a-col :span="18" class="pt-4 pb-4">
                    <div>
                      <a-button type="primary" @click="showModal">
                        上传身份证等信息
                      </a-button>
                      <a-modal
                        title="上传身份证等信息"
                        :visible="visible"
                        @ok="handleOk"
                        :afterClose="() => {
                      this.visible = false
                    }"
                        @cancel="() => {
                      this.visible = false
                    }"
                      >
                        <p>
                          <a-radio-group v-model="fileUpload.file_name" >
                            <a-radio value="身份证">
                              身份证
                            </a-radio>
                            <a-radio value='户口本'>
                              户口本
                            </a-radio>
                          </a-radio-group>
                        </p>
                        <br>
                        <p><input type="file"  @change="uploadChange"></p>
                        <br>
                      </a-modal>
                    </div>
                  </a-col>
                  <a-col :span="18" class="pt-4 pb-4">
                    <p>
                      <a-tag color="#004996">
                        2
                      </a-tag>
                      <span style="margin-left: 5px">家庭收入证明</span>
                    </p>
                  </a-col>
                  <a-col :span="18" class="pt-4 pb-4">
                    <div>
                      <div>
                        <a-button type="primary" @click="showModal1">
                          家庭收入证明
                        </a-button>
                        <a-modal
                          title="家庭收入证明"
                          :visible="visible1"
                          @ok="handleOk"
                          :afterClose="() => {
                      this.visible1 = false
                    }"
                          @cancel="() => {
                      this.visible1 = false
                    }"
                        >
                          <br>
                          <p><input type="file"  @change="uploadChange"></p>
                          <br>
                        </a-modal>
                      </div>
                    </div>
                  </a-col>
                  <a-alert
                    message="如果您非个人电脑上操作，离开时请务必删除文件！"
                    description="如果您的文件全部储存在手机中，可以下载电脑版微信将手机中文件转移至电脑上。"
                    type="info"
                    show-icon
                    class="mb-3 mt-3"
                  />
                  <a-col :span="24" class="text-center mt-4">
                    <a-button type="danger" block @click="()=>{
                      this.util.stepStatus --
                    }" class="prev">
                      上一步
                    </a-button>
                    <a-button type="primary" block @click="onclick" class="next">
                      下一步
                    </a-button>
                  </a-col>
                </a-row>

              </div>
              <div v-if="util.stepStatus === 4 || util.stepStatus === 5">
                <!--提交完成-->
                <a-row type="flex" justify="space-around" align="middle">
                  <a-col :span="22">
                    <SuccessAndError :StepStatus="util.stepStatus"></SuccessAndError>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </Page>
</template>

<script>
import SuccessAndError from '@/components/business/SuccessAndError'
import Applyheader from '../../components/base/Applyheader.vue'
export default {
  name: 'LawAssistance',
  components: {
    SuccessAndError,
    Applyheader
  },
  activated () {
    this.$api.get('/api/city').then((res) => {
      this.util.city = res.data.collection
    })
    this.goLoginTip(this.$route.path)
    var phone = this.$Cookies.get('phone')
    if (phone !== '' && phone !== undefined) {
      if (this.$Cookies.get('level') !== '1') {
        this.$message.info('请先实名认证')
        window.location.replace('/my#/workorder')
      }
      this.applyInfo.sponsor_phone = phone
      this.applyInfo.sponsor_idcard_no = this.$Cookies.get('idcard_no') === null ? '' : this.$Cookies.get('idcard_no')
      this.applyInfo.sponsor_name = this.$Cookies.get('user_name') === null ? '' : this.$Cookies.get('user_name')
      this.applyInfo.city = this.$Cookies.get('city') === null ? '' : this.$Cookies.get('city')
      if (this.$Cookies.get('area') === this.$Cookies.get('city')) {
        this.applyInfo.area = ''
        this.applyInfo.city = ''
      } else {
        this.applyInfo.area = this.$Cookies.get('area')
      }
      this.applyInfo.peo_sex = this.$Cookies.get('sex') === '1' ? '男' : '女'
    }
  },
  data () {
    return {
      visible: false,
      visible1: false,
      util: {
        stepStatus: 1,
        area: [],
        city: [],
        currentCitykey: ''
      },
      layout: {
        labelCol: { span: 4, offset: 0 },
        wrapperCol: { span: 16, offset: 0 }
      },
      defaultFileList: [
      ],
      post: {
        service_name: '法律援助在线办理'
      },
      applyInfo: {
        address: '',
        area: '',
        business_type: '',
        city: '',
        nation: '汉族',
        order_desc: '',
        order_type: '案件',
        person_type: '',
        platform_channel: '门户',
        platform_type: '网络',
        province: '',
        reserve_handle_time: '',
        reserve_person: '',
        reserve_provider: '',
        service_type: '案件-法律援助',
        sponsor_idcard_no: '',
        sponsor_idcard_type: '身份证',
        sponsor_name: '',
        sponsor_phone: '',
        town: '',
        income: '',
        profile: '',
        reason: '',
        peo_sex: '',
        human_type: ''
      },
      human_type: [],
      profiles: [],
      rules: {
        sponsor_name: [
          { required: true, message: '请输入姓名', trigger: 'change' },
          { mix: 2, max: 6, message: '2-6个中文', trigger: 'change' }
        ],
        sponsor_idcard_no: [
          { required: true, message: '请输入身份证号码', trigger: 'change' },
          { validator: this.idcard18, trigger: 'change' }
        ],
        sponsor_phone: [
          { required: true, message: '请输入手机号码', trigger: 'change' },
          {
            validator: this.enableSendCode,
            trigger: 'change'
          }
        ],
        area: [
          { required: true, message: '请选择县', trigger: 'change' }
        ],
        city: [
          { required: true, message: '请选择市', trigger: 'change' }
        ],
        nation: [
          { required: true, message: '请选择民族', trigger: 'change' }
        ],
        order_desc: [
          { required: true, message: '此项不能为空', trigger: 'change' }
        ],
        household: [],
        address: [],
        otherOne: [],
        otherAddress: [],
        income: [
          { required: true, message: '此项不能为空', trigger: 'change' }
        ],
        profiles: [
          { required: true, message: '此项不能为空', trigger: 'change' }
        ],
        reason: [
          { required: true, message: '此项不能为空', trigger: 'change' }
        ],
        peo_sex: [
          { required: true, message: '此项不能为空', trigger: 'change' }
        ],
        human_type: [
          { required: true, message: '此项不能为空', trigger: 'change' }
        ]
      },
      other: {
        col: [],
        income: [],
        profile: [],
        why: [],
        nation: [],
        gender: [
          { item_name: '男', item_value: '男' },
          { item_name: '女', item_value: '女' }
        ],
        profileType: []
      },
      fileUpload: {
        file_name: '',
        file_type: '',
        file: null,
        remarks: ''
      }
    }
  },
  methods: {
    cityChange (value, options) {
      this.applyInfo.area = ''
      if (value === '') {
        this.util.area = []
        return
      }
      var obj = {}
      obj = options.find((item) => {
        return item.district_id === value
      })
      this.applyInfo.city = obj.district_name
      this.$api.get('/api/area?parent=' + value).then((res) => {
        this.util.area = res.data.collection
      })
    },
    income (value, index) {
      this.applyInfo.income = value
      this.util.income = index
    },
    profile (value, index) {
      this.applyInfo.profile = value
      this.util.profile = index
    },
    why (value, index) {
      this.applyInfo.why = value
      this.util.why = index
    },
    gender (value, index) {
      this.applyInfo.gender = value
      this.util.gender = index
    },
    profileType (value, index) {
      this.applyInfo.profileType = value
      this.util.profileType = index
    },
    enableSendCode (rule, value, callback) {
      const passwordReg = /^1\d{10}$/
      if (passwordReg.test(value)) {
        callback()
      } else {
        callback(new Error('格式输入错误'))
      }
    },
    idcard18 (rule, value, callback) {
      const id = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/
      if (id.test(value)) {
        callback()
      } else {
        callback(new Error('格式输入错误'))
      }
    },
    uploadChange (e) {
      this.fileUpload.file = e.target.files[0]
    },
    uploadCommit () {
      var form = new FormData()
      form.append('file_name', this.fileUpload.file_name)
      form.append('file_type', this.fileUpload.file.type)
      form.append('file', this.fileUpload.file)
      form.append('remarks', this.fileUpload.remarks)
      // 请求
      this.$api.post('/api/workorder/' + this.$Cookies.get('workorder_id') + '/workorder_file?', form, { 'Content-Type': 'multipart/form-data' }).then(res => {
        if (res.status === 200) {
          this.$message.success('提交成功')
        } else {
          this.$message.error('提交失败')
        }
        this.fileUpload.file_name = ''
        this.fileUpload.file_type = ''
        this.fileUpload.file = null
        this.fileUpload.remarks = ''
      })
    },
    onclick () {
      if (this.util.stepStatus === 2) {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.applyInfo.human_type = (this.human_type.toString()).replace(/\[|]/g, '')
            this.applyInfo.profile = (this.profiles.toString()).replace(/\[|]/g, '')
            this.$api.post('/api/my-workorder/apply', this.applyInfo).then((res) => {
              if (res.status === 200) {
                this.$Cookies.set('workorder_id', res.data.data.workorder_id)
                this.$Cookies.set('order_code', res.data.data.order_code)
                this.util.stepStatus++
              } else {
                this.util.stepStatus = this.util.stepStatus + 2
              }
            })
          } else {
            this.$message.error('您当前有未填写或填写格式不正确')
            return false
          }
        })
      } else {
        this.util.stepStatus++
      }
    },
    showModal () {
      this.visible = true
    },
    showModal1 () {
      this.visible1 = true
    },
    handleOk (e) {
      this.visible = false
      this.visible1 = false
      this.uploadCommit()
    }
  },
  created () {
    this.util.stepStatus = 1
    this.$api.get('/schemas/workorder').then((res) => {
      this.other.income = res.data.model.attrs.find(function (x) {
        return x.name === 'income'
      }).typeObject.data.collection
      this.other.profile = res.data.model.attrs.find(function (x) {
        return x.name === 'profile'
      }).typeObject.data.collection
      this.other.why = res.data.model.attrs.find(function (x) {
        return x.name === 'reason'
      }).typeObject.data.collection
      this.other.profileType = res.data.model.attrs.find(function (x) {
        return x.name === 'human_type'
      }).typeObject.data.collection
      this.other.nation = res.data.model.attrs.find(function (x) {
        return x.name === 'nation'
      }).typeObject.data.collection
    })
  }
}
</script>
<style scoped src="../../assets/css/apply.css"></style>
