<template>
  <div>
    <a-result
      status="success"
      v-if="StepStatus === 4"
      title="您已成功申请预约！请点击个人中心查看办理进度。"
    >
      <div slot="subTitle">
<!--        <p>尊敬的用户:您好!您反映的问题已受理,工单号：{{ $Cookies.get('order_code') }}您可以通过登录XX省公共法律服务平台电脑端或微信小程序，个人中心查看办理进度及结果。</p>-->
      </div>
      <template #extra>
        <a-button key="console" type="primary">
          <a href="/my#/workorder" class="public-people">个人中心</a>
        </a-button>
        <a-button key="buy" @click="goHome">
          首页
        </a-button>
      </template>
    </a-result>
    <a-result
      status="error"
      title="失败"
      sub-title="您可以申请重试"
      v-if="StepStatus === 5"
    >
      <template #extra>
        <a-button key="console" type="primary" @click="refluhs">
          重试
        </a-button>
        <a-button key="buy" @click="goHome">
          首页
        </a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
export default {
  name: 'SuccessAndError',
  props: ['StepStatus'],
  methods: {
    goHome () {
      this.$router.push({ path: '/' })
    },
    refluhs () {
      location.reload()
    }
  }
}
</script>

<style scoped>

</style>
